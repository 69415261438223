a {
  text-decoration: none;
}

/* body {
  height: 100%;
  width: 100%;
  background-color: #eceef9;
} */

footer {
  color: #ededed;
  /* background-color: #414042; */
  background: linear-gradient(
    109deg,
    rgba(19, 19, 49, 1) 17%,
    rgba(9, 7, 71, 1) 47%,
    rgba(19, 16, 113, 1) 65%,
    rgba(42, 89, 168, 1) 93%
  );
  position: relative;
  bottom: 0;
}

.row {
  display: flex;
  padding: 1rem;
}

.column {
  width: calc(100% / 4);
  /* padding: 0 0.9375rem; */
}

h4 {
  margin-bottom: 0.9375rem;
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.3rem;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin-bottom: 0.625rem;
}

ul li a {
  color: #faf3f3;
  font-size: 1rem;
}

ul li i {
  color: #ffffff;
}

ul li a:hover {
  /* color: #3d3489; */
  color: aliceblue;
  border-radius: 25px;
  background: linear-gradient(
    109deg,
    rgba(19, 19, 49, 1) 17%,
    rgba(9, 7, 71, 1) 47%,
    rgba(19, 16, 113, 1) 65%,
    rgba(42, 89, 168, 1) 93%
  );
}

ul.social-icons {
  display: flex;
  column-gap: 0.625rem;
}

.social-icons i {
  color: rgb(0, 0, 0);
}

ul.social-icons li {
  margin-bottom: 0rem;
}

ul.social-icons li a {
  display: grid;
  place-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-color: #fcfcfc;
}

ul.social-icons li:hover a {
  /* background-color: #fb7d00; */
  color: aliceblue;
  /* border-radius: 25px; */
  background: linear-gradient(
    109deg,
    rgb(228, 228, 231) 17%,
    rgba(9, 7, 71, 1) 97%,
    rgba(19, 16, 113, 1) 65%,
    rgba(42, 89, 168, 1) 93%
  );
}

.copyright {
  text-align: center;
  color: #fff;
  /* background-color: #414042; */
  background: linear-gradient(
    109deg,
    rgba(19, 19, 49, 1) 17%,
    rgba(9, 7, 71, 1) 47%,
    rgba(19, 16, 113, 1) 65%,
    rgba(42, 89, 168, 1) 93%
  );
  /* padding: 0.6rem 0; */
}

/* Responsive adjustments */

@media screen and (max-width: 768px) {
  .column {
    margin: 0.4rem 0;
    width: 100%;
    padding: 0;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  h4 {
    font-size: 1.3125rem;
  }

  p {
    font-size: 0.9375rem;
  }

  ul li a {
    font-size: 0.9375rem;
  }

  ul.social-icons li a {
    width: 1.875rem;
    height: 1.875rem;
  }
}
