/*
* font-family: 'Roboto', sans-serif;
* font-weight: 100, 400, 400-italic, 700;
*/

.strong {
  font-weight: 700;
}

.img {
  max-width: 100%;
}

.p {
  font-size: 1.325rem;
  color: #222222;
  margin: 0 0 15px;
  line-height: 1.6;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}

.h1 {
  color: #000000;
  font-size: 36px;
}

@media (min-width: 768px) {
  .h1 {
    font-size: 80px;
  }
}

.h2 {
  color: #222222;
  font-size: 30px;
}

@media (min-width: 768px) {
  .h2 {
    font-size: 60px;
  }
}

.h3 {
  color: #444444;
  font-size: 24px;
}

@media (min-width: 768px) {
  .h3 {
    font-size: 50px;
  }
}

.h4 {
  color: #555555;
  font-size: 22px;
}

@media (min-width: 768px) {
  .h4 {
    font-size: 40px;
  }
}

.h5 {
  color: #666666;
  font-size: 20px;
}

@media (min-width: 768px) {
  .h5 {
    font-size: 35px;
  }
}

.h6 {
  color: #777777;
  font-size: 18px;
}

@media (min-width: 768px) {
  .h6 {
    font-size: 32px;
  }
}

section {
  padding: 90px 15px;
}

.a.cta {
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  background-color: #f72d3d;
  border: 1px solid #d41c2a;
  border-radius: 25px;
  color: #ffffff;
  text-transform: uppercase;
  display: inline-block;
  -webkit-box-shadow: rgba(100, 100, 111, 0.8) 0px 7px 19px 0px;
  box-shadow: rgba(100, 100, 111, 0.8) 0px 7px 19px 0px;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.a.cta:hover {
  background-color: #f75763;
  border: 1px solid #3b1215;
  color: #000000;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}

.container img {
  padding: 0.25rem;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
}

.banner {
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(76, 103, 153, 0.725)),
      to(rgba(186, 186, 186, 0.5))
    ),
    url(../images/banner.jpg) no-repeat;
  background: linear-gradient(
      90deg,
      rgba(176, 211, 255, 0.8),
      rgba(0, 0, 0, 0.5)
    ),
    url(../images/banner.jpg) no-repeat;
  background-position: top right;
  background-size: cover;
}

.banner h1 {
  color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  font-weight: 700;
}

.banner p {
  color: #ffffff;
  font-size: 1.375rem;
  letter-spacing: 1.5px;
  font-weight: 100;
  text-shadow: 2px 2px 7px #222222;
}

.first {
  /* background-color: #ffffff; */
  /* background-image: linear-gradient(315deg, #a7a8a8 0%, #e9ebec 74%); */
}

.first .container {
  max-width: 800px;
}

.second {
  background-color: #fff8e6;
}

.second .container {
  display: block;
}

@media (min-width: 768px) {
  .second .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .second .container .left-img {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }
}

@media (min-width: 768px) {
  .second .container .right-content {
    -ms-flex-preferred-size: 65%;
    flex-basis: 65%;
  }
}

.second .container .right-content h2 {
  margin: 30px 0 0;
}

@media (min-width: 768px) {
  .second .container .right-content h2 {
    margin: 0;
  }
}

.third {
  background-color: #e6f5fc;
}

@media (min-width: 768px) {
  .third .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.third .container .left-content {
  -ms-flex-preferred-size: 68%;
  flex-basis: 68%;
}

.third .container .right-img {
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
}

.four {
  /* background-color: #c6fcb8; */
  background-color: #07074952;
}

.four .container {
  display: block;
}

@media (min-width: 768px) {
  .four .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.four .container .member {
  background-color: #c0c6fa;
  padding: 20px;
  margin: 0 0 60px;
  border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (min-width: 768px) {
  .four .container .member {
    -ms-flex-preferred-size: 31%;
    flex-basis: 31%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .four .container .member h3 {
    font-size: 32px;
  }
}

.four .container .member .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.four .container .member .social a img {
  border: none;
  max-width: 40px;
}

.five {
  background-color: #10336759;
}

.five .container {
  max-width: 700px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 7px 29px 0px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 7px 29px 0px;
}

.five .container .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.five .container .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.six .container {
  text-align: center;
  max-width: 800px;
}
