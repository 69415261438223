/*  */
#basic-nav-dropdown {
  color: #fffcfc;
  padding-left: 50px;
}
#basic-nav-dropdown :hover {
  color: #bad7c8;
  background-color: #11703e;
  border-radius: 10px;
}

/* .navbar-links li.navbar-dropdown .dropdown a {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
} */

/* .navbar-dropdown .dropdown a:hover {
  padding-left: 30px;
} */

/* .navbar-links li a:hover {
  color: hwb(0 93% 5% / 0.437);
} */
.hover:hover {
  /* color: linear-gradient(
    109deg,
    rgba(19, 19, 49, 1) 17%,
    rgba(9, 7, 71, 1) 47%,
    rgba(19, 16, 113, 1) 65%,
    rgba(42, 89, 168, 1) 93%
  ); */
  color: aliceblue;
  border-radius: 25px;
  background: linear-gradient(
    109deg,
    rgba(19, 19, 49, 1) 17%,
    rgba(9, 7, 71, 1) 47%,
    rgba(19, 16, 113, 1) 65%,
    rgba(42, 89, 168, 1) 93%
  );
}
/* .header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  background-image: url("http://lorempixel.com/1366/698/food/");
  background-size: cover;
} */

.header-inner {
  text-align: center;
  color: #ff3f34;
  text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.8);
}

.header-inner h1 {
  font-family: "Great Vibes", cursive;
  font-size: 130px;
}

.header-inner form input[type="search"] {
  position: relative;
  width: 500px;
}
