/*------------------------------------ card section start------------------------------ */

.mui {
  --card-gradient: rgba(0, 0, 0, 0.8);
  --card-gradient: #5e9ad9, #e271ad;
  --card-gradient: tomato, orange;
  --card-blend-mode: overlay;
  --card-blend-mode: multiply;
  width: 352px;

  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);
  padding-bottom: 1rem;
  background-image: linear-gradient(
    var(--card-gradient),
    white max(9.5rem, 27vh)
  );
  overflow: hidden;
}
.hideScrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  overflow: -moz-scrollbars-none;
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Show scrollbar on hover */
.hideScrollbar:hover {
  /* Show scrollbar for Chrome, Safari and Opera */
  overflow: auto;
  /* Show scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}
.image {
  border-radius: 0.2rem 0.2rem 0 0;
  width: 100%;
  object-fit: cover;

  aspect-ratio: 4/3;
  mix-blend-mode: var(--card-blend-mode);
  filter: grayscale(100);
}

:last-child {
  margin-bottom: 0;
}

:hover,
:focus-within {
  --card-gradient: #24a9d5 max(8.5rem, 20vh);
}

.mui h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.mui a {
  color: inherit;
}

.hue {
  color: rgb(240, 81, 2);
  font-weight: bold;
  animation: hue 5s infinite;
}
.wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
  /* grid-gap: 1.5rem; */
  max-width: 100vw;
  width: 110ch;
  /* padding-left: 5rem; */
  /* padding-right: 1rem; */
}
/*------------------------------------ card section End------------------------------ */

/*------------------------------------ Footer section Start ------------------------------ */

.bu {
  background-color: rgb(255, 255, 255);
  color: white;
  border-radius: 10px;
  border: 2px solid rgba(19, 16, 113, 1) 65%;
  width: 250px;
  text-decoration: none;
}
.bu:hover {
  color: aliceblue;
  /* border-radius: 25px; */
  background: linear-gradient(
    109deg,
    rgba(19, 19, 49, 1) 17%,
    rgba(9, 7, 71, 1) 47%,
    rgba(19, 16, 113, 1) 65%,
    rgba(42, 89, 168, 1) 93%
  );
  text-decoration: none;
}
.footer {
  background-color: #fefeff;
  padding: 20px;
  text-align: center;
  padding-bottom: 10vh;
}

.social-media {
  margin-top: 10px;
}

.social-media a {
  display: inline-block;
  margin-right: 10px;
  color: #333;
  font-size: 20px;
}

.social-media a:hover {
  color: #cb1414;
}
h3 {
  color: white;
}
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: linear-gradient(
    90deg,
    rgba(9, 28, 64, 1) 1%,
    rgba(9, 23, 48, 1) 95%,
    rgba(9, 18, 32, 1) 100%,
    rgba(0, 0, 0, 1) 100%
  );
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #2a935f;
}
.single-cta i {
  color: #17dc17;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #d9d3d3;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #16a538;
}
.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #1bca6c;
}
.footer-widget ul li a {
  color: #ffffff;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #c0c0c0;
  border: 2px solid #1fd744;
  color: #000000;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: linear-gradient(
    90deg,
    hsla(152, 100%, 50%, 1) 0%,
    hsla(233, 53%, 30%, 1) 100%
  );
  padding: 13px 20px;
  border: 1px solid hsla(152, 100%, 50%, 1) 50%;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: linear-gradient(
    250deg,
    rgba(9, 28, 64, 1) 1%,
    rgba(9, 23, 48, 1) 95%,
    rgba(9, 18, 32, 1) 100%,
    rgba(0, 0, 0, 1) 100%
  );
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #fbf9f9;
}
.copyright-text p a {
  color: #30e90a;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #14ff4f;
}
.footer-menu li a {
  font-size: 14px;
  color: #ffffff;
}
/*------------------------------------ Footer section End ------------------------------ */

/*------------------------------------ Button section Start ------------------------------ */

.button-64 {
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-64:active,
.button-64:hover {
  outline: 0;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-64:hover span {
  background: none;
}

@media (min-width: 768px) {
  .button-64 {
    font-size: 24px;
    min-width: 196px;
  }
}
.containerss {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-cont {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.button-cont button {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: rgb(236 255 250 / 72%);
  border: none;
  border-left: 5px solid rgb(168, 167, 167);
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
}

.button-cont button.active {
  border-left-color: rgb(29, 105, 212);
}
/*------------------------------------ Button section End ------------------------------ */

/*------------------------------------ Form section Start ------------------------------ */

.form-containers {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}

.form-containers.show {
  opacity: 1;
  height: auto;
}
.part-container {
  flex: 1;
  margin-right: 10px;
}
/*------------------------------------ Form section End ------------------------------ */
.style-0 {
  background-image: linear-gradient(
      90deg,
      rgba(176, 211, 255, 0.8),
      rgba(0, 0, 0, 0.5)
    ),
    url("https://www.globallogic.com/in/wp-content/themes/GLTheme/assets/images/Contact-default.jpg");
  font-family: "DM Sans", sans-serif;
  background-position: 50% 0%, 50% 0%;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  height: 535.2px;
  background-color: rgb(65, 64, 66);
  box-sizing: border-box;
}

.style-1 {
  max-width: 1140px;
  font-family: "DM Sans", sans-serif;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.style-2 {
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: -12px;
  margin-left: -12px;
}

.style-3 {
  flex: 0 0 auto;
  width: 50%;
  font-family: "DM Sans", sans-serif;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  max-width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-top: 0px;
}

.style-4 {
  font-family: "DM Sans", sans-serif;
  position: absolute;
  width: 100%;
  left: 0px;
  height: 535.2px;
  margin: 0px 15px;
  box-sizing: border-box;
}

.style-5 {
  font-family: "DM Sans", sans-serif;
  position: absolute;
  bottom: 53.5125px;
  top: 372.688px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
}

.style-6 {
  font-size: 44px;
  font-weight: 100;
  font-family: "DM Sans", sans-serif;
  line-height: 52.8px;
  color: rgb(255, 255, 255);
  margin-bottom: 12px;
  box-sizing: border-box;
  margin-top: 0px;
}

.style-7 {
  font-family: "DM Sans", sans-serif;
  font-weight: 100;
  color: rgb(243, 112, 55);
  box-sizing: border-box;
}

.style-8 {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 19.6px;
  margin-bottom: 5px;
  box-sizing: border-box;
  margin-top: 0px;
}
.style-9 {
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
  padding-top: 24px;
}

.style-10 {
  font-size: 36px;
  font-weight: 100;
  font-family: "DM Sans", sans-serif;
  letter-spacing: -1px;
  line-height: 43.2px;
  color: rgb(65, 64, 66);
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 8px;
}

.style-11 {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: rgb(109, 110, 113);
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 16px;
}
/* //-----------------------------? */
.main-container {
  min-height: 100vh;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  height: 560px;
  overflow: hidden;
}
.main {
  margin: 0px auto;
  width: 480px;
  height: 480px;
  position: relative;
}
.big-circle {
  height: 100%;
  width: 100%;
  position: relative;
  border: 3px solid #6495f2;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  animation: Rotate 20s linear infinite;
  -webkit-animation: Rotate 20s linear infinite;
}
.icon-block {
  width: 64px;
  height: 64px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-image: linear-gradient(180deg, #4967e6 0%, #627bf4 100%);
  -webkit-background-image: linear-gradient(180deg, #4967e6 0%, #627bf4 100%);
  box-shadow: 0 2px 4px 0 #3e5ada;
  -webkit-box-shadow: 0 2px 4px 0 #3e5ada;
}
.icon-block img {
  margin: 0px auto;
  width: 86%;
  animation: Rotate-reverse 20s linear infinite;
  -webkit-animation: Rotate-reverse 20s linear infinite;
}
.icon-block:first-child {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.icon-block:nth-child(2) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
}
.icon-block:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
}
.icon-block:nth-child(4) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
/* circle content */
.circle {
  animation: circle-rotate 20s linear infinite;
  -webkit-animation: circle-rotate 20s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  width: 75%;
  height: 75%;
  border: 3px solid #6495f2;
  border-radius: 50%;
}
.circle .icon-block img {
  animation: img-rotate 20s linear infinite;
  -webkit-animation: img-rotate 20s linear infinite;
}
/* center logo */
.center-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.center-logo img {
  max-width: 200px;
}

/* keyframe animation */

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Rotate-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes Rotate-reverse {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(405deg);
  }
}

@-webkit-keyframes circle-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(405deg);
  }
}

@keyframes img-rotate {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(-405deg);
  }
}

@-webkit-keyframes img-rotate {
  from {
    -webkit-transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(-405deg);
  }
}
/* Adjustments for smaller screens */
@media only screen and (max-width: 768px) {
  .d-flex {
    flex-direction: column;
    align-items: center;
  }

  .big-circle {
    display: none;
  }

  .center-logo {
    margin-top: 20px;
  }
}

/* Styles for larger screens */
@media only screen and (min-width: 768px) {
  .big-circle {
    display: flex;
    justify-content: center;
  }

  .circle {
    display: flex;
    justify-content: center;
  }

  .icon-block {
    margin: 10px;
  }

  .center-logo {
    display: none;
  }
}
.paragraph-container {
  max-width: 800px; /* Adjust max width as needed */
  margin: 0 auto; /* Center the paragraph */
  padding: 0 20px; /* Add some padding for better readability */
}

.paragraph {
  font-size: 20px;
  font-weight: bolder; /* Default font size */
}

/* Responsive styles */
@media (max-width: 768px) {
  .paragraph {
    font-size: 14px; /* Reduce font size for smaller screens */
  }
}

@media (max-width: 576px) {
  .paragraph {
    font-size: 12px; /* Further reduce font size for extra small screens */
  }
}

/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  text-decoration: none;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.hue {
  color: rgb(240, 81, 2);
  font-weight: bold;
  animation: hue 5s infinite;
}

@keyframes hue {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }
  100% {
    -webkit-filter: hue-rotate(700deg);
  }
}
