.styles-0 {
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(76, 103, 153, 0.725)),
      to(rgba(186, 186, 186, 0.5))
    ),
    url("https://www.globallogic.com/in/wp-content/uploads/sites/21/2022/06/Career-desktop.jpeg")
      no-repeat;
  background: linear-gradient(
      90deg,
      rgba(176, 211, 255, 0.8),
      rgba(0, 0, 0, 0.5)
    ),
    url("https://www.globallogic.com/in/wp-content/uploads/sites/21/2022/06/Career-desktop.jpeg")
      no-repeat;
  /* background-image: url("https://www.globallogic.com/in/wp-content/uploads/sites/21/2022/06/Career-desktop.jpeg"); */
  font-family: "DM Sans", sans-serif;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 535.2px;
  /* background-color: rgb(1, 50, 244); */
  box-sizing: border-box;
}

.styles-1 {
  max-width: 1140px;
  font-family: "DM Sans", sans-serif;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.styles-2 {
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: -12px;
  margin-left: -12px;
}

.styles-3 {
  flex: 0 0 auto;
  width: 100%;
  font-family: "DM Sans", sans-serif;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  max-width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-top: 0px;
}

.styles-4 {
  font-family: "DM Sans", sans-serif;
  position: absolute;
  width: 100%;
  left: 0px;
  height: 535.2px;
  margin: 0px 15px;
  box-sizing: border-box;
}

.styles-5 {
  font-family: "DM Sans", sans-serif;
  bottom: -64.2125px;
  position: absolute;
  top: 223.812px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
}

.styles-6 {
  font-size: 44px;
  font-weight: 100;
  font-family: "DM Sans", sans-serif;
  line-height: 52.8px;
  color: rgb(255, 255, 255);
  margin-bottom: 12px;
  box-sizing: border-box;
  margin-top: 0px;
}

.styles-7 {
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
}

.styles-8 {
  font-family: "DM Sans", sans-serif;
  font-weight: 100;
  color: rgba(204, 79, 12, 0.793);
  box-sizing: border-box;
  font-size: 45px;
}

.styles-9 {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 19.6px;
  margin-bottom: 5px;
  box-sizing: border-box;
  padding-bottom: 24px;
  margin-top: 0px;
}
@keyframes myAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#myElement {
  animation: myAnimation 2s infinite;
}
.custom-card {
  width: 18rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.custom-card Card {
  height: 100%;
}
